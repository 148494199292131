import React, { useState } from 'react'
import { FaQuoteLeft } from 'react-icons/fa'
import {reviewsData} from '../assets/data/data'


const Reviews = () => {
  const [showAll, setShowAll] = useState(false);

  const DisplayedReviewsData = showAll ? reviewsData : reviewsData.slice(0, 5);

  const handleToggle = () => {
    setShowAll(!showAll);
  };
  return (
    <div className="container  min-h-screen bg-background mx-auto">

<div className="mt-16 sm:px-10 px-2">
<h1 className='text-heading sm:text-[2.5rem] text-[1.75rem] font-[400] sm:font-[700]'>Reviews</h1>

<div className="mt-10 mb-10 overflow-y-hidden ">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 overflow-y-hidden">
          {DisplayedReviewsData.map((testimonial, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg transform transition-transform hover:scale-105">
              <p><FaQuoteLeft className='text-[#ff9500] sm:text-2xl text-lg' /></p>
              <p className="text-gray-800 text-lg mb-4 italic text-justify">&nbsp; &nbsp;{testimonial.review}</p>
              <h3 className="text-[#ff9500] text-xl font-semibold text-right">- {testimonial.name}</h3>
            </div>
          ))}
        </div>
        {reviewsData.length > 5 && (
          <div className="text-center overflow-y-hidden mt-8">
            <button onClick={handleToggle} className="text-white hover:bg-[#ff8800] bg-[#ff9500] px-4 py-2 rounded-md">
              {showAll ? 'Show Less' : 'View All Reviews'}
            </button>
          </div>
        )}
      </div>
</div>
    </div>
  )
}

export default Reviews
