export const faqs = [
    {
        ques: 'What is a "home appraisal"?',
        ans: "A home appraisal is a professional assessment of a property's market value conducted by a certified appraiser. This value is based on factors such as location, condition, and comparable sales in the area. Appraisals are crucial for various purposes, including home sales, refinancing, and determining property taxes."
    },
    {
        ques: "How is my home's appraisal value determined?",
        ans: "The appraisal value of your home is determined through an in-depth analysis conducted by a professional appraiser. They consider factors such as the property's location, size, condition, and features, as well as recent sales of comparable properties in the area. The appraiser may also take into account any improvements or upgrades made to the property."
    },
    {
        ques: "What can increase my home's appraisal value?",
        ans: "Several factors can positively influence your home's appraisal value. These include maintaining the property in good condition, making strategic upgrades or renovations, and enhancing curb appeal. External factors like a strong local job market and desirable neighborhood amenities can also boost your home's appraised value."
    },
    {
        ques: "How do free home appraisal estimates work?",
        ans: "Free home appraisal estimates use automated valuation models (AVMs) that analyze public records, user-submitted data, property listings, and recent sales through algorithms. These estimates provide a quick snapshot of your home's potential value. However, for a more precise and accurate appraisal, it is recommended to consult with a professional appraiser."
    },
    {
        ques: "How often should I get my home appraised?",
        ans: "While there's no strict rule, it's beneficial to get your home appraised periodically, especially before selling, refinancing, or making significant renovations. Regular appraisals can help you understand your property's current market value and make informed decisions regarding your real estate investments."
    },
    {
        ques: "What's next after I receive my appraisal estimate?",
        ans: "After receiving your appraisal estimate, you should connect with a professional appraiser for a comprehensive evaluation. This detailed appraisal will give you a more accurate understanding of your home's value. Additionally, you can use the appraisal to inform your selling price, refinance terms, or property tax assessments."
    }
]



export const reviewsData = [
    {
        review: "Free House Appraisal is amazing! They gave me a valuation that perfectly matched recent sales in my area. It's a game-changer.",
        name: 'Sarah Williams'
    },
    {
        review: "So easy to use and incredibly helpful. I was thinking of selling my house but didn't know where to begin. Free House Appraisal gave me a solid starting point.",
        name: 'Michael Johnson'
    },
    {
        review: "This service is fantastic. I got a fair appraisal for my home within minutes. Highly recommended for anyone considering selling.",
        name: 'Priya Singh'
    },
    {
        review: "Free House Appraisal is a lifesaver! They not only provided a valuation but also gave insights into local market trends. I'm thoroughly impressed.",
        name: "David Brown"
    },
    {
        review: "I appreciate the detailed breakdown of factors affecting my home's value. Free House Appraisal offers much more than just a number – it's a wealth of information.",
        name: "Sophia Martinez"
    },
    {
        review: "Accurate, fast, and fair! I couldn't ask for more from an appraisal service. I'm highly recommending Free House Appraisal to friends and family.",
        name: "Daniel Chen"
    },
    {
        review: "As a first-time homebuyer, Free House Appraisal helped me understand the market and negotiate a fair price. This is an essential service for anyone in real estate.",
        name: "Emma Rodriguez"
    },
    {
        review: "The user-friendly interface and clear instructions made it easy to get a quick appraisal. I'm so glad I found Free House Appraisal.",
        name: "James Khan"
    },
    {
        review: "I was amazed by how comprehensive their appraisal was. They took into account everything from square footage to recent renovations. Truly impressive.",
        name: "Maya Thompson"
    },
    {
        review: "I was skeptical at first, but Free House Appraisal delivered! The valuation was spot-on, and the process was incredibly smooth. I'm a fan!",
        name: "Isabella Lopez"
    },
    {
        review: "I was hesitant to trust an online appraisal, but Free House Appraisal's reputation for fairness is well-deserved. I'm so happy with the results I received.",
        name: "Omar Patel"
    },
    {
        review: "Free House Appraisal gave me the confidence I needed to sell my house at a fair price. I'm so grateful for this resource.",
        name: "Lily Nguyen"
    },
    {
        review: "I was blown away by how fair the valuation was, especially considering it's a free service. I would definitely use Free House Appraisal again.",
        name: "Mohammed Ali"
    },
    {
        review: "Free House Appraisal is a must-have for any homeowner looking to sell quickly. It's a quick and easy way to get a realistic valuation.",
        name: "Hannah Lee"
    },
    {
        review: "I love how transparent Free House Appraisal is about the factors that influence their valuations. It's clear that they prioritize fairness and accuracy.",
        name: "Olivia Garcia"
    },
    {
        review: "Free House Appraisal exceeded my expectations! The appraisal was comprehensive and insightful, helping me make informed decisions about my property.",
        name: "Yusuf Ahmed"
    },
    {
        review: "As a real estate agent, I often recommend Free House Appraisal to my clients. It's a valuable tool for understanding the local market and making informed decisions.",
        name: "Sophie Taylor"
    }
];

