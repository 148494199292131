import React from 'react'
import logo from '../assets/images/logo-free-house-appraisal.png'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <div className='bg-lightGray shadow-inner'>
      <div className="container mx-auto">
        <div className="flex justify-between items-center py-2 sm:py-3">
          <div className="">
            <Link to='/'>
            <img className='sm:w-[120px] w-[100px]' src={logo} alt="Logo - Free House Appraisal" />
            </Link>
          </div>
          <div className="overflow-y-hidden ">
            <Link to='/reviews' className='bg-heading text-[1rem] px-2 py-1 rounded-md hover:bg-hoverClr text-lightGray  hover:text-background'>Reviews</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
