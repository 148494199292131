import React from 'react';
import { motion } from 'framer-motion';
import illustration from '../assets/images/appraisal.png';
import { Link } from 'react-router-dom';
import img1 from '../assets/images/Century 21.png'
import img2 from '../assets/images/Coldwell Banker New.png'
import img3 from '../assets/images/keller williams.png'
import img4 from '../assets/images/remax.png'
import {faqs} from '../assets/data/data'
import { Accordion } from "flowbite-react";


const Home = () => {
  const trustedBy = [img1, img2, img3, img4]
  return (
    <div className='container min-h-screen mx-auto '>
      <div className="trustedBg">
      <div className="bg-background bg-opacity-75">
      <div className="sm:flex sm:flex-row flex-col sm:justify-between items-center mt-16 mb-16 sm:px-10 px-2">
        <motion.div 
          className="sm:w-1/2 mb-10 sm:mb-0"
          
        >
          <div className="mx-auto leading-loose">
            <motion.h3
              className='text-lightGray sm:text-[1rem] text-[0.75rem] border-y-2 border-heading w-fit'
              
            >
              Want
            </motion.h3>
            <motion.h1
              className='text-heading sm:text-[2.5rem] text-[1.75rem] font-[400] sm:font-[700]'
              
            >
              Free House Appraisal<span className='text-lightGray'>?</span>
            </motion.h1>
            <motion.p
              className='text-lightGray sm:text-[1rem] text-[0.75rem] mb-4 text-justify'
              
            >
              Get a free home appraisal to accurately assess your property's market
              value and make informed decisions about selling, refinancing, or insuring your home.
            </motion.p>
            <br />
            <Link to='/free-house-appraisal' className='w-fit'>
              <motion.div
                className='bg-heading w-fit mb-4 sm:text-[1.25rem] text-[1rem] font-[400] px-2 rounded-md hover:bg-hoverClr text-lightGray hover:text-background sm:ml-8'
                animate={{ scale: [1, 1.1, 1] }}
                transition={{ duration: 0.25, repeat: Infinity, repeatDelay: 1 }}
              >
                Get Appraisal
              </motion.div>
            </Link>
          </div>
        </motion.div>
        <motion.div
          className="sm:w-1/2"
          
        >
          <img className='sm:w-[70%] sm:ml-auto w-full' src={illustration} alt="free house appraisal illustration" />
        </motion.div>
      </div>
      </div>
      <div className=" bg-smText -mt-1 sm:px-10 px-2  ">
        <div className="mt-16 mb-16">
          <h2 className='text-heading sm:text-[2rem] text-[1.5rem] font-[400] text-center mb-8 sm:font-[700]'>Trusted By</h2>
        
        <div className="flex justify-center items-center gap-[10%] sm:px-16">
         {
            trustedBy.map((img, index) => {
              return (
                
                  <img src={img} className='w-[23%]' alt="trusted by" />
                
              )
            })
          }
         </div>
        </div>
      </div>
      <div className=" bg-black bg-opacity-75  sm:px-10 px-2  ">
        <div className="mt-16 mb-16">
          <h2 className='text-heading sm:text-[2rem] text-[1.5rem] font-[400] text-center mb-8 sm:font-[700]'>FAQs</h2>
        
          <div className='w-full sm:w-[70%] mx-auto'>
            {faqs.map((faq, index) => (
              <Accordion key={index} collapseAll className=''>
                <Accordion.Panel>
                  <Accordion.Title className="flex items-center justify-between text-[1rem] sm:text-xl text-heading text-start font-[500] px-2 py-3 border-b-2 border-slate-300">
                    {faq.ques} 
                    {/* Arrow will now be automatically positioned correctly */}
                  </Accordion.Title>
                  <Accordion.Content>
                    <p className="text-[1rem] text-smText px-2 py-1 font-[300]">{faq.ans}</p>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
      </div>
      
    </div>
  );
}

export default Home;
