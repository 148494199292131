
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './components/Home'
import Error from './components/Error'
import Form from './components/Form'
import Reviews from './components/Reviews'

function App() {
  return (
    <div >
      <Router>
        <Header/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/free-house-appraisal" element={<Form />} />
          <Route path='/reviews' element={<Reviews/>}/>
          <Route path="*" element={<Error />} />
        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
