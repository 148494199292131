import React from 'react'
import logo from '../assets/images/logo-free-house-appraisal light.png'
import { AiFillTikTok, AiFillInstagram, AiFillLinkedin, AiFillFacebook   } from "react-icons/ai";


const Footer = () => {
  return (
    <div className='bg-black shadow-inner'>
      <div className="container mx-auto">
      <div className="  bg-black py-4 px-2">
        <div className="max-w-[1170px] mx-auto">
        <div className="flex flex-col sm:flex-row  sm:items-start">

<div className="sm:w-1/2 mt-2">
  <img src={logo} className='sm:w-[55%] w-[50%] mx-auto sm:mx-0' alt="Logo True Value of My House"  />
</div>

<div className="sm:w-2/2 mt-8 sm:mt-0">
  <h3 className='text-xl sm:text-2xl font-[400] text-heading text-center'>Privacy Disclaimer</h3>
  <p className='text-[0.75rem] text-smText font-[400] text-center'>“Free House Appraisal” respects your privacy. We do not sell, rent, or share your personal information, including your email address and phone number, with any third parties for marketing purposes. The information you provide is used solely for the purpose of responding to your inquiries and providing you with real estate services.</p>
</div>

<div className="sm:w-1/2 mt-8 sm:mt-0 ">
  <h3 className='text-xl sm:text-2xl font-[400] text-heading sm:text-end text-center'>Contact Us:</h3>
  <p className='text-[0.75rem] text-smText font-[400] sm:text-end text-center'>
    <a href='mailto:info@truevalueofmyhouse.com'>info@freehouseappraisal.com</a>
  </p>

  <div className='flex flex-wrap justify-center sm:justify-end gap-2'>
    <a href='/' target='_blank' className='mt-2 w-fit text-[1.75rem] hover:text-smText text-heading font-[200]'><AiFillFacebook /></a>
    <a href='/' target='_blank' className='mt-2 w-fit text-[1.75rem] hover:text-smText text-heading font-[200]'><AiFillTikTok /></a>
    <a href='/' target='_blank' className='mt-2 w-fit text-[1.75rem] hover:text-smText text-heading font-[200]'><AiFillInstagram /></a>
    <a href='/' target='_blank' className='mt-2 w-fit text-[1.75rem] hover:text-smText text-heading font-[200]'><AiFillLinkedin /></a>
  </div>
</div>



</div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Footer
