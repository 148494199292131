import React, { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import toast, { Toaster } from 'react-hot-toast';

const apiKey = "AIzaSyBxGzkNl-UJi9fvk-3CINzJ2fjvwD0spUo";
const mapApiJs = "https://maps.googleapis.com/maps/api/js";

function loadAsyncScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  });
}

const Form = () => {
  const form = useRef();
  const [isSending, setIsSending] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSending(true);

    emailjs
      .sendForm('service_mn6pvgn', 'template_22plvoo', form.current, 'iuJTYIiJsqgMe89pn')
      .then((result) => {
        console.log(result.text);
        form.current.reset();
        toast.success('Message Sent Successfully!');
      }, (error) => {
        console.log(error.text);
        toast.error(error.text);
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  const searchInput = useRef(null);

  const initMapScript = () => {
    if (window.google) {
      return Promise.resolve();
    }

    const existingScript = document.querySelector('script[src*="maps.googleapis.com/maps/api/js"]');
    if (existingScript) {
      return Promise.resolve();
    }

    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };

  const initAutoComplete = () => {
    if (!searchInput.current || !window.google) return;

    const waitForGoogle = setInterval(() => {
      if (window.google.maps.places) {
        clearInterval(waitForGoogle);

        const autoComplete = new window.google.maps.places.Autocomplete(
          searchInput.current,
          {
            componentRestrictions: { country: "us" },
            autoComplete: true,
          }
        );

        autoComplete.setFields(["formatted_address", "address_component", "geometry"]);

        autoComplete.addListener("place_changed", () => {
          const place = autoComplete.getPlace();
          if (!place || !place.formatted_address) return;
        });
      }
    }, 100);
  };

  useEffect(() => {
    initMapScript().then(() => initAutoComplete());
  }, []);

  return (
    <div className='container min-h-screen mx-auto bg-background'>
      <div className="mt-16 sm:px-10 px-2">
        <div>
          <h1 className='text-heading sm:text-[2.5rem] text-[1.75rem] font-[400] sm:font-[700]'>Get Free Appraisal</h1>
        </div>

        <div className="sm:w-[80%] mt-10 py-9 sm:px-4 px-2  mx-auto w-[95%]">
          <form ref={form} onSubmit={sendEmail}>
            <input
              type="text"
              placeholder="Enter Your Address"
              className="w-[100%] sm:w-[85%] text-heading p-2 focus:outline-none mx-auto block rounded-[5px]"
              name="from_location"
              ref={searchInput}
              required
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                }
              }}
            />

            <div className="block sm:flex justify-center items-center gap-8 mt-3 p-1">
              <input
                placeholder="Your Name..."
                className="w-[90%] bg-transparent text-heading italic sm:w-[85%] px-3 py-2 mx-auto block rounded-[5px] focus:outline-none mb-4 sm:mb-0 ring-2 ring-heading focus:ring-2 focus:ring-lightGray text-[0.85rem] sm:text-[1rem]"
                type="text"
                name="from_name"
                required
              />
              <input
                placeholder="Your Number..."
                className="w-[90%] bg-transparent text-heading italic sm:w-[85%] px-3 py-2 mx-auto block rounded-[5px] focus:outline-none ring-2 ring-heading focus:ring-2 focus:ring-lightGray text-[0.85rem] sm:text-[1rem]"
                type="number"
                name="from_number"
                required
              />
            </div>

            <div className="block sm:flex justify-center items-center gap-8 mt-3 p-1">
              <input
                placeholder="Your Email..."
                className="w-[90%] bg-transparent text-heading italic sm:w-[85%] px-3 py-2 mx-auto block rounded-[5px] focus:outline-none mb-4 sm:mb-0 ring-2 ring-heading focus:ring-2 focus:ring-lightGray text-[0.85rem] sm:text-[1rem]"
                type="email"
                name="from_email"
                required
                pattern='[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$'

              />
              <select
                placeholder="Condition of House"
                className="w-[90%] bg-transparent cursor-pointer text-heading italic sm:w-[85%] px-3 py-2 mx-auto block rounded-[5px] focus:outline-none  ring-2 ring-heading focus:ring-2 focus:ring-lightGray text-[0.85rem] sm:text-[1rem]"
                name="house_condition"
                required

              >
                <option className='text-gray-500 ' value="" disabled selected>Condition of House</option>
                <option className='text-black ' value="New">New</option>
                <option className='text-black ' value="Needs a little work">Needs a little work</option>
                <option className='text-black ' value="Needs significant work">Needs significant work</option>
                <option className='text-black ' value="Gut-Job">Gut-Job</option>
              </select>
            </div>

            <div className="block sm:flex justify-center items-center gap-8 mt-3 p-1">
              <select
                placeholder="Your Property Type"
                className="w-[90%] bg-transparent cursor-pointer text-heading italic sm:w-[85%] mb-4 sm:mb-0 px-3 py-2 mx-auto block rounded-[5px] focus:outline-none ring-2 ring-heading focus:ring-2 focus:ring-lightGray text-[0.85rem] sm:text-[1rem]"
                name="property_type"
                required

              >
                <option className='text-gray-500 ' value="" disabled selected>Property Type</option>
                <option className='text-black ' value="Single Family Home">Single Family Home</option>
                <option className='text-black ' value="Multi-family">Multi-family</option>
                <option className='text-black ' value="Condominium">Condominium</option>
                <option className='text-black ' value="Townhouse">Townhouse</option>
              </select>
              <select
                placeholder="Reason For Appraisal"
                className="w-[90%] bg-transparent cursor-pointer text-heading italic sm:w-[85%] px-3 py-2 mx-auto block rounded-[5px] focus:outline-none ring-2 ring-heading focus:ring-2 focus:ring-lightGray text-[0.85rem] sm:text-[1rem]"
                name="appraisal_reason"
                required

              >
                <option className='text-gray-500 ' value="" disabled selected>Reason For Appraisal</option>
                <option className='text-black ' value="Sell">Sell</option>
                <option className='text-black ' value="Refinance">Refinance</option>
                <option className='text-black ' value="Just Estimate">Just Estimate</option>
              </select>
            </div>

            <div className="block sm:flex justify-center items-center gap-8 mt-3 p-1">
              <select
                placeholder="Preferred Way of Receiving Appraisal"
                className="w-[90%] bg-transparent cursor-pointer text-heading italic mb-4 sm:mb-0 sm:w-[85%] px-3 py-2 mx-auto block rounded-[5px] focus:outline-none ring-2 ring-heading focus:ring-2 focus:ring-lightGray text-[0.85rem] sm:text-[1rem]"
                name="receiving_preference"
                required

              >
                <option className='text-gray-500 ' value="" disabled selected>Preferred Way of Receiving Appraisal</option>
                <option className='text-black ' value="SMS">SMS</option>
                <option className='text-black ' value="WhatsApp">WhatsApp</option>
                <option className='text-black ' value="Email">Email</option>
              </select>
              <button
                className='w-[90%] bg-heading hover:text-background text-white italic sm:w-[85%] px-3 py-2 mx-auto block rounded-[5px] text-center text-[0.85rem] sm:text-[1rem] ring-2 ring-heading'
                type="submit"
                disabled={isSending}
              >
                {isSending ? 'Sending...' : 'Send'}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default Form;
