import React from 'react'
import { Link } from 'react-router-dom'

const Error = () => {
  return (
    <div className='container min-h-screen mx-auto bg-background'>
      <div className='flex justify-center items-center h-screen bg-background'>
        <div className="text-center overflow-y-hidden">
          <h1 className='text-white text-4xl mb-4  overflow-y-hidden'>404 - Page Not Found</h1>
          <Link to="/"
            className='bg-heading w-fit mb-4 sm:text-[1.25rem] text-[1rem] font-[400]  px-3 py-1 rounded-md hover:bg-hoverClr text-lightGray hover:text-background '
          >Home</Link>
        </div>
      </div>
    </div>
  )
}

export default Error

